import React, { useState, useCallback } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../app.scss";

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import faqsList from './faqs.json';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Logo from "../assets/images/Landing/svg/logo.svg";

const filter = (options, params) => {
  const keys = params.inputValue.toLowerCase().split(" ");
  const lastWord = keys[keys.length - 1] === '' ? null : keys[keys.length - 1];
  const filtered = lastWord 
  ? options.filter(item => item.toLowerCase().match(lastWord)) 
  : options.filter(item => item.toLowerCase().match(keys[keys.length - 2]));
  return filtered;
};

const useStyles = makeStyles((theme) => ({
  sroot: {
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
  },
  search: {
 
    '& .MuiInputLabel-formControl': {
      zIndex: '10000',
      paddingLeft: '20px',
      cursor: 'text',
    },

    '& .MuiAutocomplete-clearIndicator': {
      marginRight:'5px !important',
    },

    '&: .MuiInputBase-root.MuiInputLabel': {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
    },
 
    '& .MuiInputBase-root': {
      backgroundColor: '#ffffffc7',
      borderRadius: '25px',
      boxShadow: '0 0 0 3px #fff',
      transition: 'all .3s ease',

      '&:hover': {
        backgroundColor: '#fff',
      },

      '&.Mui-focused': {
        backgroundColor: '#fff',
      },

      '&::before': {
        borderBottom: 'none',
      },

      '& .MuiInputBase-input': {
        paddingLeft: '20px !important',
        color: '#777',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      },

      '&.MuiAutocomplete-inputRoot': {
        borderBottom: 'none !important',

        '&::before': {
          borderBottom: 'none !important',
        },
      },

      '&.MuiInput-underline': {
        borderBottom: 'none !important',

        '&::after': {
          borderBottom: 'none !important',
        },
      },
      
    }
  },
  title: {
    textAlign: 'center', 
    backgroundColor: '#45bfee',
    background: 'linear-gradient(to left, #46bfee 0%, #22dedd 100%)',
    fontFamily: "Josefin Sans",
    color: '#fff',
    '& h2': {
      fontFamily: "Josefin Sans"
    },
    '& strong': {
      fontSize: '1.5em'
    }
  },
  root: {
    width: '100%',
    fontFamily: "Quicksand",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    fontFamily: "Quicksand",
    color: '#45bfee',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      textTransform: 'none',
    },
  },
  info: {
    color: '#545454'
  },
  infoCont: {
    paddingTop: '0'
  },
  titleCont: {
    '& .Mui-expanded ': {
      margin: '12px 0'
    }
  },
  accCont: {
    '& .MuiIconButton-edgeEnd': {
      marginRight: '-12px'
    },
    
    '&.Mui-expanded': {
      backgroundColor: '#e6f7fd'
    }
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));
const { keywords, list: faqs } = faqsList;

export default function FAQsDialog() {
    let showFAQbyId;
    const [value, setValue] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const classes = useStyles();

    const setRef = useCallback(node => {
      if (node && selectedQuestion && Number(node.dataset.id) === selectedQuestion) {
        if (showFAQbyId) setTimeout(()=>{node.scrollIntoView({block: "center", behavior: "smooth"});}, 0)
        else node.scrollIntoView({block: "center", behavior: "smooth"});
      }
    }, [selectedQuestion]);



  return (
      <Box sx={{ mb: 4 }}>

      <Helmet>
        <meta name="robots" content="index"/>
        <meta name="description"
            content="We transform your dreams in reality. AGM Renovations experts provide quality basement/bathroom finishing services throughout the GTA and the surrounding area. We offer 100% satisfaction guarantee on our workmanship and offer competitive prices with exceptional quality."
        />
        <meta name="keywords"
            content="basement renovations toronto, AGM basements, basement renovations toronto costs, finishing a basement cost estimator, finished basements, finished basement costs, basement finishing financing, AMG design, high qualty basements toronto, basement renovations vaughan, basement renovations in GTA, basement finish, steps to finishing a basement,"
        />
        <title>AGM Renovations - Basement and Bathroom Professionals</title>
      </Helmet>

        <div className="headerLogo">
          <Link to="/" className="logo faqs">
            <img src={Logo} alt="logo" />
          </Link>
          {/* <img className="mobileLogo" src={Logo} alt="logo" /> */}
        </div>

        <DialogTitle className={classes.title} id="alert-dialog-title">
          <>
            <strong>FAQs</strong>
            <br/>
            See Our Most Frequently Asked Questions Below
          </>
          {/* <div className={classes.sroot}>
          <Autocomplete
            className={classes.search}
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {

                for(let i = 0; i < faqs.length; i++) {
                  if (faqs[i].title === newValue) {
                    setSelectedQuestion(i);
                    break;
                  }
                }
                
                setValue({
                  title: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue({
                  title: newValue.inputValue,
                });
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => (params.inputValue === '' ? [] : filter(options, params)) }
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={faqs.map((option) => option.title)}
            getOptionLabel={(option) => {
              // console.log('option', option.title);
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              // scrollToTheQuestion(option.title);
              return option.title;
            }}
            renderOption={ value ? (option, value) => (<p>{option}</p>) : null }
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Please type your question here" />
            )}
          />
          </div> */}
        </DialogTitle>

        <DialogContent  className={classes.dialogContent}>
          <div className={classes.root}>
          {faqs.map( (item, index) => (
            <Accordion key={index} data-id={index} expanded={ Boolean('index === selectedQuestion') } 
            onChange={()=>{
              setSelectedQuestion(selectedQuestion === index ? null : index); 
              if (showFAQbyId !== null) showFAQbyId = null;
            }} 
            className={classes.accCont} >

            <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.titleCont}
            >
                <Typography  data-id={index} ref={node=>setRef(node)} className={classes.heading}>{item.title}</Typography>
            </AccordionSummary>

            <AccordionDetails className={classes.infoCont}>
                <Typography className={classes.info}>{item.a}</Typography>
            </AccordionDetails>

            </Accordion>
          ))}
          </div>
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions> */}

      </Box>
  );
}
